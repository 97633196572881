import React, { useEffect, useState } from 'react'
import './style.less'
import { Modal, Row } from 'antd'
import { IDateRange, menuModal } from '../../lib/interfaces'
import { isInsidePeriod, userNotesReceiver } from '../../lib/dateFunctions'
import { addDays, format, isSameDay, toDate } from 'date-fns'
import { Camera } from '../Camera/index'
import { UserNoteRow } from './UserNoteRow'
import { httpPost } from '../../services/http'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { colorConverter } from '../../lib/userNoteColors'
import { APIFunctions, getUserTypeFunctions } from '../../lib/apiFunctions'
import { ComplianceReportsTypes } from '../../lib/enums'
import { WhereAbouts } from './WhereAbouts'
import {
    Button,
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Popover,
    Theme,
    Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'center',
        },
        headerText: {
            margin: 'auto',
            marginLeft: 10,
            marginRight: 10,
        },
        typography: {
            padding: theme.spacing(2),
        },
        infoContainer: {
            marginRight: '20px',
        },
    })
)

const useKeyPress = function (
    targetKey,
    keyDownHandler,
    keyUpHandler,
    changeNoteContent,
    whereAboutsDropdownOpen
) {
    function downHandler({ key }) {
        if (!whereAboutsDropdownOpen && !changeNoteContent && keyDownHandler && key === targetKey) {
            keyDownHandler()
        }
    }

    const upHandler = ({ key }) => {
        if (!changeNoteContent && keyUpHandler && key === targetKey) {
            keyUpHandler()
        }
    }

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('keydown', downHandler)
            window.addEventListener('keyup', upHandler)

            return () => {
                window.removeEventListener('keydown', downHandler)
                window.removeEventListener('keyup', upHandler)
            }
        }
    })
}

export function MenuModal({
    dateClicked,
    setCurrentDate,
    visible,
    setVisible,
    title,
    startPeriod,
    dateRanges,
    deletePeriod,
    docDates,
    setDocDates,
    rosterDates,
    userNotes,
    setUserNotes,
    userLocationDates,
    setUserLocationDates,
    updateData,
    session,
    setIsBackgroundLoading,
}: menuModal) {
    const [period, setPeriod] = useState<IDateRange | false>(false)
    const [userNote, setUserNote] = useState<userNotesReceiver | false>(false)
    const [rosterCategory, setRosterCategory] = useState<string | false>(false)
    const [documentVisible, setDocumentVisible] = useState<boolean>(false)
    // const [noteVisible, setNoteVisible] = useState<boolean>(false)
    const [changeNoteContent, setChangeNoteContent] = useState<boolean>(false)
    const [addNoteLoading, setAddNoteLoading] = useState<boolean>(false)
    const [apiFunctions, setAPIFunctions] = useState<APIFunctions>(null)
    const [whereAboutsDropdownOpen, setWhereAboutsDropdownOpen] = useState<boolean>(false)

    const [anchorInfoVisible, setAnchorInfoVisible] = useState<HTMLButtonElement | null>(null)

    const infoVisible = Boolean(anchorInfoVisible)
    const infoVisibleId = infoVisible ? 'simple-popover' : undefined
    const classes = useStyles()

    useEffect(() => {
        setAPIFunctions(getUserTypeFunctions(ComplianceReportsTypes.LLUser))
    }, [])

    const startPeriodClick = () => {
        startPeriod(dateClicked)
        setVisible(false)
    }

    const deletePeriodClick = () => {
        setVisible(false)
        deletePeriod(period)
    }

    useEffect(() => {
        setChangeNoteContent(false)
    }, [dateClicked])

    useEffect(() => {
        setPeriod(isInsidePeriod(dateRanges, dateClicked))
    }, [dateClicked, dateRanges])

    useEffect(() => {
        setUserNote(userNotes.find((n) => isSameDay(n.target_date, dateClicked)) || false)
    }, [dateClicked, userNotes])

    useEffect(() => {
        setRosterCategory(
            rosterDates.find((n) => isSameDay(toDate(new Date(n.roster_date)), toDate(dateClicked)))
                ?.categories || false
        )
    }, [dateClicked, rosterDates])

    const openCameraModal = () => {
        setDocumentVisible(true)
    }

    const addNote = async () => {
        setAddNoteLoading(true)
        await httpPost(`/user-notes/${format(dateClicked, 'yyyy-MM-dd')}`, {
            content: '',
            color: 'purple',
            include_report: true,
            include_graph: true,
        })
        // setUserNotes(mapUserNotes((await httpGet<userNotesReceiver[]>('/user-notes/')).data));
        const newUserNotes: userNotesReceiver[] = [
            ...userNotes,
            {
                target_date: new Date(dateClicked),
                note_color: colorConverter['purple'],
                note_content: '',
                include_report: true,
                include_graph: true,
                created_on: null,
                modified_on: null,
            },
        ]
        setUserNotes(newUserNotes)
        setAddNoteLoading(false)
    }

    const closeModal = () => {
        setVisible(false)
        setDocumentVisible(false)
        setPeriod(false)
    }

    function changeDateRight() {
        setCurrentDate(addDays(dateClicked, 1))
    }

    function changeDateleft() {
        setCurrentDate(addDays(dateClicked, -1))
    }

    useKeyPress('ArrowLeft', changeDateleft, null, changeNoteContent, whereAboutsDropdownOpen)
    useKeyPress('ArrowRight', changeDateRight, null, changeNoteContent, whereAboutsDropdownOpen)

    async function reportChange(action: any) {
        switch (action.action) {
            case 'exclude':
            case 'include':
                const sumData = await apiFunctions.getDocDateSum(
                    session.user.user_id,
                    action.targetDate
                )
                if (
                    sumData.count > 0 &&
                    !docDates.find(
                        (dd) =>
                            dd.documentation_date === action.targetDate &&
                            dd.date_is_included_in_report
                    )
                ) {
                    setDocDates(
                        docDates.map((d) => {
                            if (
                                isSameDay(
                                    new Date(d.documentation_date),
                                    toDate(new Date(action.targetDate))
                                )
                            ) {
                                return {
                                    documentation_date: new Date(d.documentation_date),
                                    date_is_included_in_report: true,
                                }
                            }
                            return d
                        })
                    )
                } else if (sumData.count <= 0) {
                    setDocDates(
                        docDates.map((d) => {
                            if (
                                isSameDay(
                                    new Date(d.documentation_date),
                                    toDate(new Date(action.targetDate))
                                )
                            ) {
                                return {
                                    documentation_date: new Date(d.documentation_date),
                                    date_is_included_in_report: false,
                                }
                            }
                            return d
                        })
                    )
                }
                break
        }
    }

    return (
        <Modal visible={visible} onCancel={closeModal} onOk={closeModal} footer={[]}>
            {!period && (
                <Row className={classes.header}>
                    <IconButton
                        className={classes.infoContainer}
                        onClick={(e) => {
                            setAnchorInfoVisible(e.currentTarget)
                        }}
                        edge="start"
                        aria-label="info"
                    >
                        <InfoIcon />
                    </IconButton>
                    <IconButton edge="start" aria-label="left">
                        <ArrowLeftOutlined
                            style={{
                                alignSelf: 'center',
                                userSelect: 'none',
                                fontSize: 20,
                            }}
                            onClick={changeDateleft}
                        />
                    </IconButton>
                    <h3 className={classes.headerText}>{title && title}</h3>
                    <IconButton edge="end" aria-label="right">
                        <ArrowRightOutlined
                            onClick={changeDateRight}
                            style={{
                                alignSelf: 'center',
                                userSelect: 'none',
                                fontSize: 20,
                            }}
                        />
                    </IconButton>
                    <div style={{ width: 68 }} />
                </Row>
            )}
            {period && (
                <Row className={classes.header}>
                    <IconButton
                        className={classes.infoContainer}
                        onClick={(e) => {
                            setAnchorInfoVisible(e.currentTarget)
                        }}
                        edge="start"
                        aria-label="info"
                    >
                        <InfoIcon />
                    </IconButton>
                    <IconButton edge="start" aria-label="left">
                        <ArrowLeftOutlined
                            style={{
                                alignSelf: 'center',
                                userSelect: 'none',
                                fontSize: 20,
                            }}
                            onClick={changeDateleft}
                        />
                    </IconButton>
                    {!isMobile && (
                        <h3 className={classes.headerText}>
                            {title} in:{' '}
                            {format(toDate(period.start), 'dd. MMM') +
                                ' - ' +
                                format(toDate(period.end), 'dd. MMM')}
                        </h3>
                    )}
                    <IconButton edge="end" aria-label="right">
                        <ArrowRightOutlined
                            onClick={changeDateRight}
                            style={{
                                alignSelf: 'center',
                                userSelect: 'none',
                                fontSize: 20,
                            }}
                        />
                    </IconButton>
                    <div style={{ width: 68 }} />
                    {isMobile && (
                        <h3 className={classes.headerText}>
                            {title} in:{' '}
                            {format(toDate(period.start), 'dd. MMM') +
                                ' - ' +
                                format(toDate(period.end), 'dd. MMM')}
                        </h3>
                    )}
                </Row>
            )}
            {rosterCategory && (
                <Row className="menuRow">
                    <p>Roster duty: {rosterCategory}</p>
                </Row>
            )}
            {!period && (
                <Row className="menuRow">
                    <Button onClick={startPeriodClick} variant="contained" color="primary">
                        + Add DK-vacation
                    </Button>
                </Row>
            )}
            {period && (
                <Row className="menuRow">
                    <Button onClick={deletePeriodClick} variant="contained" color="secondary">
                        Delete DK-vacation
                    </Button>
                </Row>
            )}
            <Row className="menuRow">
                <Button onClick={openCameraModal} variant="contained">
                    View/upload documentation
                </Button>
            </Row>
            {!userNote && (
                <Row className="menuRow">
                    <Button
                        onClick={addNote}
                        variant="contained"
                        endIcon={addNoteLoading ? <CircularProgress color="inherit" /> : null}
                    >
                        + Add note
                    </Button>
                </Row>
            )}
            {userNote && (
                <Row className="menuRow">
                    <UserNoteRow
                        userNote={userNote}
                        setUserNotes={setUserNotes}
                        userNotes={userNotes}
                        updateData={updateData}
                        changeContent={changeNoteContent}
                        setChangeContent={setChangeNoteContent}
                        menuModalVisible={visible}
                    />
                </Row>
            )}
            <Row className="menuRow">
                <WhereAbouts
                    dateClicked={dateClicked}
                    apiFunctions={apiFunctions}
                    userId={session?.user?.user_id}
                    dropdownOpen={whereAboutsDropdownOpen}
                    setDropdownOpen={setWhereAboutsDropdownOpen}
                    setIsBackgroundLoading={setIsBackgroundLoading}
                    userLocationDates={userLocationDates}
                    setUserLocationDates={setUserLocationDates}
                />
            </Row>
            <Camera
                apiFunctions={getUserTypeFunctions(ComplianceReportsTypes.LLUser)}
                userId={session?.user?.user_id}
                dateClicked={dateClicked}
                visible={documentVisible}
                setVisible={setDocumentVisible}
                docDates={docDates}
                setDocDates={setDocDates}
                title={
                    period ? (
                        <h3>
                            {title} in:{' '}
                            {format(toDate(period.start), 'dd. MMM') +
                                ' - ' +
                                format(toDate(period.end), 'dd. MMM')}
                        </h3>
                    ) : (
                        <h3>{title && title}</h3>
                    )
                }
                reportChange={reportChange}
            />
            <Popover
                id={infoVisibleId}
                open={infoVisible}
                anchorEl={anchorInfoVisible}
                onClose={() => setAnchorInfoVisible(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>
                    Fra denne menu styrer du det data der skal indgå for{' '}
                    {format(dateClicked, 'yyyy-MM-dd')}.
                    <br />
                    Indsæt DK-dage,
                    <br />
                    overfør dokumentation,
                    <br />
                    tilføj note og/eller
                    <br />
                    angiv de steder du har opholdt dig.
                </Typography>
            </Popover>
        </Modal>
    )
}
