import React, { useEffect, useRef, useState } from 'react'
import './style.less'
import { Col, Radio, Row, Switch } from 'antd'
import { startOfToday, toDate, format, add, addMonths } from 'date-fns'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ReferenceLine,
    ReferenceArea,
} from 'recharts'
import { dataModifiers, IPeriodCache } from '../../lib/interfaces'
import { colorConverter } from '../../lib/periodColors'
import { IGraphData, IGraphPoint } from '../../lib/interfaces'
import { dateFormat } from '../../lib/dateFunctions'

function getGraphHeight() {
    if (typeof window !== 'undefined') {
        const { innerHeight: height } = window
        if (height < 800) {
            return Math.round(height / 2)
        }
        return 500
    }
    return 0
}

const allDataKey = 'all-data'

function splitData(splitKey: string, data: IGraphData, llPeriods: IPeriodCache[]): IGraphPoint[] {
    if (splitKey === allDataKey) return data.dataPoints
    let p = llPeriods.find((p) => p.id === splitKey)
    if (!p) return data.dataPoints
    return data.dataPoints.filter((dp: IGraphPoint) => {
        return (
            (p.start === null || dp.trueDate >= add(toDate(p.start), { days: -20 })) &&
            (p.end === null || dp.trueDate <= add(toDate(p.end), { days: 20 }))
        )
    })
}

const months = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
}

const getTicks = (graphData: IGraphPoint[]) => {
    if (graphData.length === 0) return []
    const startVals = graphData[0].date.split('/')
    const start = new Date(
        Number(startVals[2]),
        months[startVals[0].toLowerCase()],
        Number(startVals[1])
    )
    const endVals = graphData[graphData.length - 1].date.split('/')
    const end = new Date(Number(endVals[2]), months[endVals[0].toLowerCase()], Number(endVals[1]))
    let currentMonth = toDate(start)
    if (currentMonth.getDate() !== 1) {
        currentMonth = toDate(addMonths(toDate(start), 1))
        currentMonth.setDate(1)
    }
    let ticks = []
    while (end >= currentMonth) {
        ticks.push(format(currentMonth, dateFormat))
        currentMonth = addMonths(currentMonth, 1)
    }
    return ticks
}

function Overview({ llPeriods, allGraphData, nextDayRelease, currentPeriod }: dataModifiers) {
    const [graphTimeSetting, setGraphTimeSetting] = useState<string>(
        currentPeriod?.id || allDataKey
    )
    const [graphData, setGraphData] = useState<IGraphPoint[]>(
        splitData(graphTimeSetting, allGraphData, llPeriods)
    )
    const [graphWidth, setGraphWidth] = useState<number>(0)
    const [graphTicks, setGraphTicks] = useState<string[]>(getTicks(graphData))
    const [graphHeight, setGraphHeight] = useState(getGraphHeight())
    const [llPeriodLines, setllPeriodLines] = useState<any[]>([])
    const [maxPoint, setMaxPoint] = useState<number>(60)
    const [currentDaycount, setCurrentDaycount] = useState<number>(0)
    const [includeGeneralDays, setIncludeGeneralDays] = useState<boolean>(
        currentPeriod ? false : true
    )
    const parentRef = useRef(null)

    useEffect(() => {
        if (parentRef.current) {
            setGraphWidth((parentRef.current || { offsetWidth: 0 }).offsetWidth || 0)
        }
    }, [parentRef])

    useEffect(() => {
        setGraphData(splitData(graphTimeSetting, allGraphData, llPeriods))
    }, [graphTimeSetting, allGraphData, llPeriods])

    useEffect(() => {
        const todayPoint = allGraphData.dataPoints.find(
            (e) => e.date === format(toDate(startOfToday()), dateFormat)
        )
        if (!todayPoint) return
        setCurrentDaycount(
            currentPeriod ? todayPoint.ex_period_daycount : todayPoint.generel_daycount
        )
    }, [allGraphData, currentPeriod])

    useEffect(() => {
        if (graphTimeSetting && graphTimeSetting.length > 0) return
        setGraphTimeSetting(currentPeriod?.id || allDataKey)
        setIncludeGeneralDays(currentPeriod ? false : true)
    }, [currentPeriod, graphTimeSetting])

    useEffect(() => {
        const heights = graphData.map((p: IGraphPoint) => {
            return ((includeGeneralDays && p.generel_daycount) || p.ex_period_daycount) + 20
        })
        setMaxPoint(Math.max(...heights, 60))
    }, [graphData, includeGeneralDays])

    useEffect(() => {
        setGraphTicks(getTicks(graphData))
    }, [graphData])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setGraphHeight(getGraphHeight())
                setGraphWidth(window.innerWidth)
            }
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }
    }, [])
    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setGraphHeight(getGraphHeight())
                setGraphWidth(window.innerWidth)
            }
            window.addEventListener('orientationchange', handleResize)
            return () => window.removeEventListener('orientationchange', handleResize)
        }
    }, [])

    useEffect(() => {
        var newlllines = []
        for (var i = 0; i < llPeriods.length; i++) {
            const startDate = llPeriods[i].start
            if (startDate) {
                newlllines.push(
                    <ReferenceLine
                        x={format(toDate(startDate), dateFormat)}
                        stroke="green" //label="Period Start"
                        key={format(toDate(startDate), dateFormat)}
                    />
                )
            }
            const endDate = llPeriods[i].end
            if (endDate) {
                newlllines.push(
                    <ReferenceLine
                        x={format(toDate(endDate), dateFormat)}
                        stroke="green" //label="Period End"
                        key={format(toDate(endDate), dateFormat)}
                    />
                )
            }
        }
        setllPeriodLines(newlllines)
    }, [llPeriods])

    const renderTooltipValue = (value: string | number | (string | number)[], name: string) => {
        type dkDictType = Record<number, string>
        const dkDict: dkDictType = {
            0: 'No',
            1: 'Yes',
        }
        switch (name) {
            case 'wasindk':
                if (typeof value !== 'number' && typeof value !== 'boolean') {
                    return ['No', 'DK']
                }
                return [dkDict[Number(value)] || 'No', 'DK']
            case 'generel_daycount':
                return [value, 'Generel Daycount']
            case 'ex_period_daycount':
                return [value, 'Exemption Period Daycount']
            case 'user_note_red':
                return ['red', 'Note']
            case 'user_note_yellow':
                return ['yellow', 'Note']
            case 'user_note_purple':
                return ['purple', 'Note']
        }
        return [value, name]
    }

    const RenderLabel = (props: any) => {
        return (
            <g>
                <foreignObject x={props.viewBox.x} y={100} width={100} height={graphHeight}>
                    <p className="ex-period-label">{props.text}</p>
                </foreignObject>
            </g>
        )
    }

    return (
        <div ref={parentRef} className="chart-module">
            <Row className="row chart-container">
                <AreaChart
                    className="graph"
                    width={graphWidth}
                    height={graphHeight}
                    data={graphData}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#14143C" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#14143C" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#7EAC55" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#7EAC55" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        ticks={graphTicks}
                        key="date"
                        dataKey="date"
                        tickFormatter={(value) => {
                            if (!value?.split) return value
                            return value.split('/')[0] + ' ' + value.split('/')[2]
                        }}
                    />
                    <YAxis allowDataOverflow={true} domain={[0, maxPoint]} />
                    <ReferenceLine
                        x={format(toDate(startOfToday()), dateFormat)}
                        key={format(toDate(startOfToday()), dateFormat)}
                        stroke="black"
                        strokeWidth={3}
                        label="Today"
                    />
                    <ReferenceLine y={42} stroke="red" strokeWidth={2} />
                    {llPeriodLines}
                    <Tooltip formatter={renderTooltipValue} />
                    {includeGeneralDays && (
                        <Area
                            type="monotone"
                            dataKey="generel_daycount"
                            stroke="#14143C"
                            fill="url(#colorBlue)"
                            animationDuration={1000}
                        />
                    )}
                    <Area
                        type="monotone"
                        key="ex_period_daycount"
                        dataKey="ex_period_daycount"
                        stroke="#7EAC55"
                        fill="url(#colorGreen)"
                    />
                    <Area
                        type="monotone"
                        key="wasindk"
                        dataKey="wasindk"
                        stroke="#8884d8"
                        fill="#8884d8"
                    />
                    <Area
                        type="monotone"
                        key="user_note_red"
                        dataKey="user_note_red"
                        stroke="#f5222d"
                        fill="#f5222d"
                        dot
                    />
                    <Area
                        type="monotone"
                        key="user_note_yellow"
                        dataKey="user_note_yellow"
                        stroke="#ffc53d"
                        fill="#ffc53d"
                        dot
                    />
                    <Area
                        type="monotone"
                        key="user_note_purple"
                        dataKey="user_note_purple"
                        stroke="#531dab"
                        fill="#531dab"
                        dot
                    />
                    {llPeriods.map((p: IPeriodCache, idx) => {
                        return (
                            <ReferenceArea
                                key={`referenceline-${idx}`}
                                {...(p.start
                                    ? {
                                          x1: format(toDate(p.start), dateFormat),
                                      }
                                    : {})}
                                {...(p.end
                                    ? {
                                          x2: format(toDate(p.end), dateFormat),
                                      }
                                    : {})}
                                fill={colorConverter[p.period_color]}
                                label={
                                    <RenderLabel
                                        text={p.period_title || 'Untitled Exemption Period ' + idx}
                                    />
                                }
                                fillOpacity={0.2}
                            />
                        )
                    })}
                </AreaChart>
            </Row>
            <Row className="row">
                <Col span={24} className="radio-time">
                    <div className="switches-container">
                        <h2
                            className={
                                currentDaycount > 42 ? 'status-number-red' : 'status-number-good'
                            }
                        >
                            {currentDaycount}
                        </h2>
                        <h4 style={{ marginLeft: '10px' }}>
                            Day counter{' '}
                            {currentPeriod
                                ? `current exemption period (${42 - currentDaycount} days left)`
                                : 'past six months'}
                        </h4>
                    </div>
                    {nextDayRelease && nextDayRelease.eligible && nextDayRelease.release_date && (
                        <div className="switches-container">
                            <h2>{nextDayRelease.days}</h2>
                            <h4 style={{ marginLeft: '10px' }}>
                                Days to next day-release (
                                {format(toDate(new Date(nextDayRelease.release_date)), 'MMMM d.')})
                            </h4>
                        </div>
                    )}
                    <div className="switches-container">
                        <Switch
                            checked={includeGeneralDays}
                            onChange={() => {
                                setIncludeGeneralDays(!includeGeneralDays)
                            }}
                        />
                        <h4 style={{ marginLeft: '10px' }}>Daycount across periods</h4>
                    </div>
                    <Radio.Group
                        defaultValue={graphTimeSetting}
                        value={graphTimeSetting}
                        onChange={(value) => {
                            setGraphTimeSetting(value.target.value)
                        }}
                        buttonStyle="solid"
                        style={{ width: '100%', paddingBottom: '100px' }}
                    >
                        <div key={'period-btn-all'}>
                            <div />
                            <Radio.Button
                                style={{
                                    color: '#D7E6F5',
                                    backgroundColor: '#7EAC55',
                                    width: '100%',
                                    textAlign: 'center',
                                    marginBottom: '5px',
                                    marginTop: '5px',
                                }}
                                value={'all-data'}
                            >
                                All Data
                            </Radio.Button>
                            <div />
                        </div>
                        {llPeriods
                            .sort((a, b) => (a.start?.valueOf() || 0) - (b.start?.valueOf() || 0))
                            .map((p, idx) => {
                                return (
                                    <div key={`period-btn-${p.id}`}>
                                        <div />
                                        <Radio.Button
                                            style={{
                                                color: '#D7E6F5',
                                                backgroundColor: colorConverter[p.period_color],
                                                width: '100%',
                                                textAlign: 'center',
                                                marginBottom: '5px',
                                            }}
                                            value={p.id}
                                        >
                                            <div className="ex-period-btn-content">
                                                {p.period_title ||
                                                    `${
                                                        p.start
                                                            ? format(toDate(p.start), 'yyyy/MM/dd')
                                                            : 'Open Start'
                                                    } - ${
                                                        p.end
                                                            ? format(toDate(p.end), 'yyyy/MM/dd')
                                                            : 'Open End'
                                                    }`}
                                                {(p.max_day_count > 42 && (
                                                    <p
                                                        style={{
                                                            textAlign: 'center',
                                                            color: 'red',
                                                        }}
                                                    >
                                                        Max: {p.max_day_count}
                                                    </p>
                                                )) || (
                                                    <p style={{ textAlign: 'center' }}>
                                                        Max: {p.max_day_count}
                                                    </p>
                                                )}
                                            </div>
                                        </Radio.Button>
                                        <div />
                                    </div>
                                )
                            })}
                    </Radio.Group>
                    <div></div>
                </Col>
            </Row>
        </div>
    )
}

export default Overview
