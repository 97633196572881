import React from 'react'
import { SyncOutlined } from '@ant-design/icons'
import './AppNavBar/style.less'
import { Link } from 'gatsby'
import { GeoSnapshooter } from './GeoSnapshooter'

export function AppHeader({
    headerText,
    isBackgroundLoading,
    userLocationDates,
    setUserLocationDates,
}: {
    headerText: string
    isBackgroundLoading: boolean
    userLocationDates
    setUserLocationDates
}) {
    return (
        <div style={{ height: '56px' }}>
            <div className="nav-header">
                <div className="header-content">
                    <span
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            // justifyContent: 'center',
                            textAlign: 'right',
                        }}
                    >
                        <Link to="/">
                            <div style={{ marginRight: 20 }} className="logoImg" />
                        </Link>
                        {isBackgroundLoading && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    textAlign: 'right',
                                }}
                            >
                                <h4
                                    className="inverse-h"
                                    style={{ marginTop: '10px', marginRight: '10px' }}
                                >
                                    Updating...
                                </h4>
                                <SyncOutlined
                                    style={{ fontSize: 24, color: 'white', alignSelf: 'center' }}
                                    spin
                                />
                            </div>
                        )}
                    </span>
                    <h3 className="header-title">{headerText}</h3>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            textAlign: 'right',
                        }}
                    >
                        <GeoSnapshooter
                            isBackgroundLoading={isBackgroundLoading}
                            userLocationDates={userLocationDates}
                            setUserLocationDates={setUserLocationDates}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
