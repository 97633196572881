import { IconButton, MuiThemeProvider, Tooltip } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/Place'
import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import WhereToVoteIcon from '@material-ui/icons/WhereToVote'
import { message } from 'antd'
import Badge from '@material-ui/core/Badge'
import { httpGet, httpPost } from '../services/http'
import { format, toDate } from 'date-fns'
import { userLocation } from '../lib/interfaces'

const customTheme = (outerTheme) => ({
    ...outerTheme,
    palette: {
        ...outerTheme.palette,
        primary: {
            main: '#D7E6F5',
        },
        secondary: {
            main: '#ab7e1d',
        },
    },
})

const snapshotStates = {
    NO_SNAPSHOT: 'no_snapshot',
    LOADING: 'loading',
    HAS_SNAPSHOT: 'has_snapshot',
}

function populateUserLocationDates(userLocationDates, setUserLocationDates, position) {
    if (
        !userLocationDates.find(
            (uld) =>
                format(toDate(new Date(uld.target_date)), 'yyyy-MM-dd') ===
                format(toDate(new Date(position.timestamp)), 'yyyy-MM-dd')
        )
    ) {
        setUserLocationDates([
            ...userLocationDates,
            { target_date: toDate(new Date(position.timestamp)), location_count: 1 },
        ])
    } else {
        setUserLocationDates(
            userLocationDates.map((uld) => {
                if (
                    format(toDate(new Date(uld.target_date)), 'yyyy-MM-dd') ===
                    format(toDate(new Date(position.timestamp)), 'yyyy-MM-dd')
                ) {
                    return {
                        ...uld,
                        location_count: uld.location_count + 1,
                    }
                }
                return uld
            })
        )
    }
}

export function GeoSnapshooter({ isBackgroundLoading, setUserLocationDates, userLocationDates }) {
    const [snapshotState, setSnapshotState] = useState<string>(snapshotStates.NO_SNAPSHOT)
    const [count, setCount] = React.useState(0)

    async function getSnapshotCount() {
        const res = await httpGet<{ locations: userLocation[] }>(
            `/user-locations/${format(toDate(new Date()), 'yyyy-MM-dd')}`
        )
        const locations = res.data.locations
        const newCount = locations.filter((l) => l.is_geo_snapshot).length
        setCount(newCount)
        if (newCount > 0) {
            setSnapshotState(snapshotStates.HAS_SNAPSHOT)
        } else {
            setSnapshotState(snapshotStates.NO_SNAPSHOT)
        }
    }

    useEffect(() => {
        getSnapshotCount()
    }, [isBackgroundLoading])

    async function takeSnapshot() {
        setSnapshotState(snapshotStates.LOADING)
        if (!('geolocation' in navigator)) {
            message.error('Geo-Snapshots are not supported on your device')
            setSnapshotState(snapshotStates.NO_SNAPSHOT)
        }
        try {
            navigator.geolocation.getCurrentPosition(
                async function (position) {
                    await httpPost(
                        `/user-locations/${format(
                            toDate(new Date(position.timestamp)),
                            'yyyy-MM-dd'
                        )}/geo-snapshots`,
                        {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            altitude: position.coords.altitude,
                            accuracy: position.coords.accuracy,
                            speed: position.coords.speed,
                            altitude_accuracy: position.coords.altitudeAccuracy,
                            heading: position.coords.heading,
                            snapshot_timestamp: position.timestamp,
                        }
                    )
                    await getSnapshotCount()
                    setSnapshotState(snapshotStates.HAS_SNAPSHOT)
                    populateUserLocationDates(userLocationDates, setUserLocationDates, position)
                    message.success('Geo-Snapshot taken')
                },
                function (error) {
                    switch (error.code) {
                        case 1:
                            message.error('Geo-Snapshot: permission was denied')
                            break
                        case 2:
                            message.error('Geo-Snapshot: position unavailable')
                            break
                        default:
                            message.error('Geo-Snapshot: snapshot failed')
                            break
                    }
                    console.log(error.code, error.message)
                    setSnapshotState(snapshotStates.NO_SNAPSHOT)
                    httpGet(`/landing/status?geo-error=${error.message}&error-code=${error.code}`)
                },
                {
                    timeout: 5000,
                }
            )
        } catch (e) {
            console.log(e)
            message.error('Error:', e.toString())
            setSnapshotState(snapshotStates.NO_SNAPSHOT)
            httpGet(`/landing/status?geo-error=${e?.message}&error-code=${e?.code}`)
        }
    }

    function renderIcon() {
        switch (snapshotState) {
            case snapshotStates.NO_SNAPSHOT:
                return (
                    <Tooltip title="Take Geo-Snapshot">
                        <IconButton
                            style={{ marginRight: '15px', marginLeft: '15px' }}
                            color="primary"
                            onClick={takeSnapshot}
                            edge="end"
                            aria-label="location-snapshot"
                        >
                            <PlaceIcon style={{ color: '#D7E6F5' }} />
                        </IconButton>
                    </Tooltip>
                )
            case snapshotStates.LOADING:
                return <CircularProgress />
            case snapshotStates.HAS_SNAPSHOT:
                return (
                    <Tooltip title="Take Geo-Snapshot">
                        <IconButton
                            style={{ marginRight: '15px', marginLeft: '15px' }}
                            color="primary"
                            onClick={takeSnapshot}
                            edge="end"
                            aria-label="location-snapshot"
                        >
                            <Badge color="secondary" badgeContent={count}>
                                <WhereToVoteIcon style={{ color: '#D7E6F5' }} />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )
        }
    }

    return <MuiThemeProvider theme={customTheme}>{renderIcon()}</MuiThemeProvider>
}
