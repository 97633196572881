import { Button, Card, message, Popconfirm, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.less'
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { format, toDate } from 'date-fns'
import FileSaver from 'file-saver'
import { httpDelete, httpGet, httpPut } from '../../services/http'

export interface IRosterPeriod {
    id: string
    pdf_file_url: string
    csv_file_url: string
    date_start: Date | number
    date_end: Date | number
    include_report: boolean
}

interface IRosterCard {
    setData: (data: any) => void
    rosterPeriod: IRosterPeriod
    updateData: () => void
}

export interface IRosterPeriodRes {
    roster_periods: IRosterPeriod[]
}

export default function RosterCard({ setData, rosterPeriod, updateData }: IRosterCard) {
    const [PDFLoading, setPDFLoading] = useState(false)
    const [CSVLoading, setCSVLoading] = useState(false)
    const [delLoading, setDelLoading] = useState(false)
    const [popConfirmVisible, setPopConfirmVisible] = useState(false)
    const [includeReport, setIncludeReport] = useState<boolean>(rosterPeriod.include_report)

    useEffect(() => {
        setIncludeReport(rosterPeriod.include_report)
    }, [rosterPeriod.include_report])

    const formatRosterRange = (date_start: Date | number, date_end: Date | number): string => {
        const dateFormat = 'dd. MMM yyyy'
        return `Roster: ${format(toDate(new Date(date_start)), dateFormat)} - ${format(
            toDate(new Date(date_end)),
            dateFormat
        )}`
    }

    async function downloadPDF(fileUrl: string) {
        setPDFLoading(true)
        const fileRes = await fetch(fileUrl)
        const fileBlob = await fileRes.blob()
        FileSaver.saveAs(fileBlob, 'roster-file.pdf')
        setPDFLoading(false)
    }

    async function downloadCSV(fileUrl: string) {
        setCSVLoading(true)
        const fileRes = await fetch(fileUrl)
        const fileBlob = await fileRes.blob()
        FileSaver.saveAs(fileBlob, 'roster-file.csv')
        setCSVLoading(false)
    }

    async function deleteRoster(rosterId: string) {
        setDelLoading(true)
        try {
            await httpDelete(`/roster/${rosterId}`)
            const res = await httpGet<IRosterPeriodRes>('/roster')
            if (res.data && res.data.roster_periods) {
                setData(res.data.roster_periods)
            }
            message.success('roster period deleted successfully')
            if (typeof window !== 'undefined') {
                window.location.reload()
            }
        } catch (error) {
            message.error(`Something went wrong: ${error.data?.detail}`)
        }
        setPopConfirmVisible(false)
        setDelLoading(false)
    }

    function handleCancelDelete() {
        setPopConfirmVisible(false)
    }

    const onChangeReport = () => {
        httpPut(`/roster/${rosterPeriod.id}`, { include_report: !includeReport })
        setIncludeReport(!includeReport)
    }

    return (
        <Card
            type="inner"
            size="small"
            title={formatRosterRange(rosterPeriod.date_start, rosterPeriod.date_end)}
            className="roster-card"
        >
            <Button
                className="roster-card-btn"
                loading={PDFLoading}
                size="small"
                onClick={() => downloadPDF(rosterPeriod.pdf_file_url)}
                icon={<DownloadOutlined />}
            >
                Download PDF file
            </Button>
            <Button
                className="roster-card-btn"
                loading={CSVLoading}
                size="small"
                onClick={() => downloadCSV(rosterPeriod.csv_file_url)}
                icon={<DownloadOutlined />}
            >
                Download CSV file
            </Button>
            <Popconfirm
                title="Cofirm delete?"
                visible={popConfirmVisible}
                onConfirm={() => deleteRoster(rosterPeriod.id)}
                okButtonProps={{ loading: delLoading }}
                onCancel={handleCancelDelete}
            >
                <Button
                    className="roster-card-btn-del"
                    loading={delLoading}
                    size="small"
                    onClick={() => setPopConfirmVisible(true)}
                    danger
                    icon={<DeleteOutlined />}
                >
                    Delete Roster Period
                </Button>
            </Popconfirm>
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                <Checkbox checked={includeReport} onChange={onChangeReport}>
                    Show duty on compliance report
                </Checkbox>
            </div>
        </Card>
    )
}
