import React, { useEffect, useRef, useState } from 'react'
import './style.less'
import './calDay.less'
import './standardCalDay.less'
import {
    LeftOutlined,
    RightOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CalendarOutlined,
} from '@ant-design/icons'
import {
    addMonths,
    addYears,
    format,
    isSameMonth,
    startOfToday,
    subDays,
    subMonths,
    subYears,
    toDate,
} from 'date-fns'
import { addDays } from 'date-fns/esm'
import { layoutDictKeyFormat } from '../../lib/dateFunctions'

interface IRawCalendarInput {
    onDayClick: (day: Date | number) => void
    month: Date
    onMonthChange: (newMonth: Date) => void
    layoutDates: { [id: number]: string }
}

function RawCalendar({ onDayClick, month, onMonthChange, layoutDates }: IRawCalendarInput) {
    const [latestMonday, setLatestMonday] = useState<Date | number>(getPreviousMonday(month))
    const [cellHeight, setCellHeight] = useState<number>(0)
    const [isWide, setIsWide] = useState<boolean>(false)
    var containerElementRef = useRef<HTMLDivElement>()
    useEffect(() => {
        setLatestMonday(getPreviousMonday(new Date(month.getFullYear(), month.getMonth(), 1)))
    }, [month])

    const handlePreviousYear = () => {
        onMonthChange(subYears(month, 1))
    }

    const handleNextYear = () => {
        onMonthChange(addYears(month, 1))
    }

    const handlePreviousMonth = () => {
        onMonthChange(subMonths(month, 1))
    }

    const handleNextMonth = () => {
        onMonthChange(addMonths(month, 1))
    }

    const handleToday = () => {
        onMonthChange(startOfToday())
    }

    function getPreviousMonday(referenceDate) {
        //: Date | number) {
        var date = new Date(referenceDate)
        var day = date.getDay()
        var prevMonday = new Date(referenceDate)
        if (date.getDay() === 0) {
            prevMonday = subDays(prevMonday, 6)
        } else {
            prevMonday = subDays(prevMonday, day - 1)
        }

        return prevMonday
    }

    useEffect(function () {
        var handleResize = function handleResize() {
            var containerElement = containerElementRef.current
            var containerWidth = containerElement?.offsetWidth || 0
            var cellWidth = containerWidth / 7
            var newCellHeight = 1
            var wide = false

            if (cellWidth > 60) {
                newCellHeight += Math.round(cellWidth * 0.75)
                wide = true
            } else {
                newCellHeight += Math.round(cellWidth)
            }
            setCellHeight(newCellHeight)
            setIsWide(wide)
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize)
            handleResize()
            return function () {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [])

    return (
        <div className="root-cal">
            <div className="cal-extra-nav-container">
                <div
                    className="year-nav-btn"
                    // onTouchEnd={handlePreviousYear}
                    onClick={handlePreviousYear}
                >
                    <DoubleLeftOutlined />
                </div>
                <div
                    className="back-to-tday-btn"
                    // onTouchEnd={handleToday}
                    onClick={handleToday}
                >
                    <CalendarOutlined />
                </div>
                <div
                    className="year-nav-btn"
                    // onTouchEnd={handleNextYear}
                    onClick={handleNextYear}
                >
                    <DoubleRightOutlined />
                </div>
            </div>
            <div className="cal-extra-nav-container">
                <div
                    className="year-nav-btn"
                    // onTouchEnd={handlePreviousMonth}
                    onClick={handlePreviousMonth}
                >
                    <LeftOutlined />
                </div>
                <h4 style={{ alignSelf: 'center', fontSize: 16 }}>{format(month, 'MMMM yyyy')}</h4>
                <div
                    className="year-nav-btn"
                    // onTouchEnd={handleNextMonth}
                    onClick={handleNextMonth}
                >
                    <RightOutlined />
                </div>
            </div>
            <div className="simple-cal-week-header">
                <span className="simple-cal-week-header_day">Mon</span>
                <span className="simple-cal-week-header_day">Tue</span>
                <span className="simple-cal-week-header_day">Wed</span>
                <span className="simple-cal-week-header_day">Thu</span>
                <span className="simple-cal-week-header_day">Fri</span>
                <span className="simple-cal-week-header_day">Sat</span>
                <span className="simple-cal-week-header_day">Sun</span>
            </div>
            <div className="simple-cal-body" ref={containerElementRef}>
                {[0, 1, 2, 3, 4, 5].map((i) => {
                    return (
                        <div className="simple-cal-row" key={`row-${i.toString()}`}>
                            {[0, 1, 2, 3, 4, 5, 6].map((j) => {
                                const cellDate = addDays(latestMonday, i * 7 + j)
                                return (
                                    <div
                                        className={
                                            `simple-cal-day ${isWide ? '-wide' : ''}` +
                                            ' ' +
                                            (layoutDates[
                                                format(toDate(cellDate), layoutDictKeyFormat)
                                            ] || '')
                                        }
                                        style={{
                                            height: `${cellHeight}px`,
                                        }}
                                        onClick={() => onDayClick(cellDate)}
                                        key={`cell-${i.toString()}.${j.toString()}`}
                                    >
                                        {cellDate.getDate() === 1 && (
                                            <span className="simple-cal-day_month">
                                                {format(cellDate, 'LLL')}
                                            </span>
                                        )}
                                        <span
                                            className={
                                                'simple-cal-day_date' +
                                                (isSameMonth(toDate(cellDate), toDate(month))
                                                    ? ''
                                                    : ' simple-cal-day-outside')
                                            }
                                        >
                                            <span className={'simple-cal-day_date_child_layer'}>
                                                {format(cellDate, 'd')}
                                            </span>
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RawCalendar
