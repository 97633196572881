import React, { useEffect, useState } from 'react'
import './style.less'
import { isSameDay, isAfter, format, toDate, isBefore } from 'date-fns'
import 'react-nice-dates/build/style.css'
import { Button, message, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { startOfToday } from 'date-fns/esm'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { PeriodConfig } from './PeriodConfig'
import { dataModifiers, ILLChange, IPeriodCreateModal, IDateRange } from '../../lib/interfaces'
import {
    dataReceiver,
    getCalendarLayoutDates,
    isInsidePeriod,
    mapPeriodData,
} from '../../lib/dateFunctions'
import { httpDelete, httpGet, httpPost } from '../../services/http'
import { MenuModal } from '../CalendarDayMenu'
import RawCalendar from './RawCalendar'
import { editType } from '../../lib/enums'

function SimpleCalendarPage({
    dateRanges,
    setDateRanges,
    isSelecting,
    setIsSelecting,
    setIsLoading,
    docDates,
    setDocDates,
    llPeriods,
    setSelectingEXPeriod,
    selectingEXPeriod,
    updateData,
    session,
    userNotes,
    setUserNotes,
    userLocationDates,
    setUserLocationDates,
    rosterDates,
    calendarMonth,
    setCalendarMonth,
    setIsBackgroundLoading,
}: dataModifiers) {
    const [menuModalVisible, setMenuModalVisible] = useState<boolean>(false)
    const [isNotified, setIsNotified] = useState<boolean>(false)
    const [menuModalText, setMenuModalText] = useState<string | undefined>(undefined)
    const [currentDate, setCurrentDate] = useState<Date | number>(startOfToday())
    const [createEXPeriod, setCreateEXPeriod] = useState<IPeriodCreateModal>({
        periodCreate: null,
        changeType: null,
        visible: false,
    })
    const [layoutDates, setLayoutDates] = useState<{ [id: number]: string }>({})

    useEffect(() => {
        setLayoutDates(
            getCalendarLayoutDates(
                dateRanges,
                docDates,
                userNotes,
                rosterDates,
                llPeriods,
                userLocationDates
            )
        )
    }, [dateRanges, docDates, userNotes, rosterDates, llPeriods, userLocationDates])

    useEffect(() => {
        if (
            !isNotified &&
            session.user?.current_period_end &&
            isBefore(toDate(session.user?.current_period_end), startOfToday())
        ) {
            setIsNotified(true)
            Modal.error({
                title: 'Subscription inactive',
                content: 'Go to Settings > Manage Billing / Subscribe to activate your account!',
            })
        }
    }, [session.user?.current_period_end, isNotified])

    useEffect(() => {
        setMenuModalText(format(toDate(currentDate), 'dd. MMMM'))
    }, [currentDate])

    const startPeriod = (date: any) => {
        setIsLoading(true)
        if (isInsidePeriod(dateRanges, date)) {
            setIsLoading(false)
            Modal.error({
                title: 'You cannot have overlapping periods',
                content:
                    'Either edit or delete the existing period or start your period another time',
            })
            return
        }
        setDateRanges([...dateRanges, { start: date, end: null }])
        setIsSelecting(true)
        setIsLoading(false)
    }

    const cancelSelect = () => {
        if (isSelecting) {
            setDateRanges(dateRanges.slice(0, dateRanges.length - 1))
        }
        setSelectingEXPeriod({
            period: null,
            changeType: null,
        })
        setIsSelecting(false)
        setCreateEXPeriod({
            periodCreate: createEXPeriod.periodCreate,
            changeType: null,
            visible: false,
        })
    }

    const insertPeriod = async (
        dateRangeToInsert: IDateRange,
        showModalOnError: boolean = true
    ) => {
        try {
            await httpPost('/period', {
                start: format(toDate(dateRangeToInsert.start), 'yyyy-MM-dd'),
                end: format(toDate(dateRangeToInsert.end), 'yyyy-MM-dd'),
            })
            message.success('Added dk period!')
            setDateRanges(mapPeriodData((await httpGet<dataReceiver>('/periods')).data))
            await updateData('day-release graph ll-period')
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            if (error.status && error.status === 402) {
                Modal.error({
                    title: 'Subscription inactive',
                    content:
                        'Go to Settings > Manage Billing / Subscribe to activate your account!',
                })
                return
            }
            if (dateRanges.length > 0 && !dateRanges[dateRanges.length - 1].end) {
                setDateRanges(dateRanges.slice(0, dateRanges.length - 1))
            }
            if (showModalOnError) {
                Modal.error({
                    title: 'Could not complete period',
                    content: error.data?.detail,
                })
            }
        }
    }

    const deletePeriod = async (dateRangeToDelete: IDateRange) => {
        setIsLoading(true)
        try {
            await httpDelete('/period', {
                start: format(toDate(dateRangeToDelete.start), 'yyyy-MM-dd'),
                end: format(toDate(dateRangeToDelete.end), 'yyyy-MM-dd'),
            })
            message.info('Deleted dk period!')
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            if (error.status && error.status === 402) {
                Modal.error({
                    title: 'Subscription inactive',
                    content:
                        'Go to Settings > Manage Billing / Subscribe to activate your account!',
                })
                return
            }
            Modal.error({
                title: 'Could not remove dk period',
                content: error.data.detail,
            })
            return
        }
        setDateRanges(
            dateRanges.filter(
                (dateRange) =>
                    !(
                        dateRange.start === dateRangeToDelete.start &&
                        dateRange.end === dateRangeToDelete.end
                    )
            )
        )
        await updateData('day-release graph ll-period')
    }

    async function showDKConfirm(date: number | Date, changeType: editType) {
        let result: string | boolean | null = 'notDone'
        const selectHintStart =
            'The start of exemption periods have to be at the last day of a stay in DK'
        const selectHintEnd =
            'The end of exemption periods have to be at the first day of a stay in DK'
        Modal.confirm({
            title: changeType === editType.Start ? selectHintStart : selectHintEnd,
            icon: <ExclamationCircleOutlined />,
            content:
                'Can you confirm that you ' +
                (changeType === editType.Start
                    ? isAfter(toDate(date), startOfToday())
                        ? 'will be leaving'
                        : 'left'
                    : isAfter(toDate(date), startOfToday())
                        ? 'will be arriving in'
                        : 'arrived in') +
                ' Denmark on ' +
                format(toDate(date), 'yyyy-MM-dd') +
                '?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                return new Promise<void>((resolve, reject) => {
                    insertPeriod({ start: date, end: date }, false)
                        .then((r) => {
                            result = changeType === editType.Start
                            resolve()
                        })
                        .catch((error) => {
                            if (error.data && error.data.detail) {
                                message.error(error.toString())
                            }
                            console.log(error)
                            result = null
                            reject()
                        })
                }).catch(() => console.log('Oops errors!'))
            },
            onCancel() {
                result = null
            },
        })
        while (result === 'notDone') {
            await new Promise((resolve) => {
                setTimeout(resolve, 100)
            })
        }
        return result
    }

    const handleDayClick = async (date: number | Date | null) => {
        if (date === null) {
            return
        }
        setIsLoading(true)
        setCurrentDate(date)
        if (
            isSelecting === false &&
            selectingEXPeriod.changeType === null &&
            createEXPeriod.changeType === null
        ) {
            setMenuModalVisible(true)
        } else if (selectingEXPeriod.changeType) {
            var is_start
            var fromConfirmModal = false
            if (
                selectingEXPeriod.changeType === editType.Start &&
                dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.end, date))
            ) {
                is_start = true
            } else if (
                selectingEXPeriod.changeType === editType.End &&
                dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.start, date))
            ) {
                is_start = false
            } else if (
                dateRanges.some((dateRange: IDateRange) => {
                    return (
                        dateRange.end !== null &&
                        isAfter(toDate(date), toDate(dateRange.start)) &&
                        isAfter(toDate(dateRange.end), toDate(date))
                    )
                }) ||
                (selectingEXPeriod.changeType === editType.Start &&
                    dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.start, date))) ||
                (selectingEXPeriod.changeType === editType.End &&
                    dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.end, date)))
            ) {
                const selectHintStart =
                    'The start of exemption periods have to be at the last day of a stay in DK'
                const selectHintEnd =
                    'The end of exemption periods have to be at the first day of a stay in DK'
                Modal.error({
                    title: `Invalid date to ${selectingEXPeriod.changeType} exemption period`,
                    content:
                        selectingEXPeriod.changeType === editType.Start
                            ? selectHintStart
                            : selectHintEnd,
                })
                setIsLoading(false)
                return
            } else {
                is_start = await showDKConfirm(date, selectingEXPeriod.changeType)
                if (is_start === null) {
                    setIsLoading(false)
                    return
                }
                fromConfirmModal = true
            }
            setIsLoading(true)
            try {
                await httpPost<ILLChange>('/change-ll-period', {
                    update_date: format(toDate(date), 'yyyy-MM-dd'),
                    is_start: is_start,
                    ll_period: selectingEXPeriod.period?.id,
                })
                message.success('Updated exemption period!')
                setIsLoading(false)
            } catch (error) {
                if (error.status && error.status === 402) {
                    Modal.error({
                        title: 'Subscription inactive',
                        content:
                            'Go to Settings > Manage Billing / Subscribe to activate your account!',
                    })
                    setIsLoading(false)
                    return
                }
                if (fromConfirmModal) {
                    await deletePeriod({ start: date, end: date })
                }
                setIsLoading(false)
                Modal.error({
                    title: `Invalid date to ${selectingEXPeriod.changeType} exemption period`,
                    content: error.data?.detail,
                })
                return
            }
            setSelectingEXPeriod({
                period: null,
                changeType: null,
            })
            // await updateData();
            await updateData('day-release graph ll-period')
        } else if (createEXPeriod.changeType) {
            if (
                createEXPeriod.changeType === editType.Start &&
                dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.end, date))
            ) {
                setCreateEXPeriod({
                    visible: true,
                    periodCreate: {
                        start: date,
                        end: createEXPeriod.periodCreate?.end || null,
                    },
                    changeType: null,
                })
            } else if (
                createEXPeriod.changeType === editType.End &&
                dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.start, date))
            ) {
                setCreateEXPeriod({
                    visible: true,
                    periodCreate: {
                        start: createEXPeriod.periodCreate?.start || null,
                        end: date,
                    },
                    changeType: null,
                })
            } else if (
                dateRanges.some((dateRange: IDateRange) => {
                    return (
                        dateRange.end !== null &&
                        isAfter(toDate(date), toDate(dateRange.start)) &&
                        isAfter(toDate(dateRange.end), toDate(date))
                    )
                }) ||
                (createEXPeriod.changeType === editType.Start &&
                    dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.start, date))) ||
                (createEXPeriod.changeType === editType.End &&
                    dateRanges.some((dateRange: IDateRange) => isSameDay(dateRange.end, date)))
            ) {
                const selectHintStart =
                    'The start of exemption periods have to be at the last day of a stay in DK'
                const selectHintEnd =
                    'The end of exemption periods have to be at the first day of a stay in DK'
                Modal.error({
                    title: `Invalid date to ${createEXPeriod.changeType} exemption period`,
                    content:
                        createEXPeriod.changeType === editType.Start
                            ? selectHintStart
                            : selectHintEnd,
                })
                setIsLoading(false)
                return
            } else {
                is_start = await showDKConfirm(date, createEXPeriod.changeType)
                if (is_start === null) {
                    setIsLoading(false)
                    return
                }
                if (is_start) {
                    setCreateEXPeriod({
                        visible: true,
                        periodCreate: {
                            start: date,
                            end: createEXPeriod.periodCreate?.end || null,
                        },
                        changeType: null,
                    })
                } else {
                    setCreateEXPeriod({
                        visible: true,
                        periodCreate: {
                            start: createEXPeriod.periodCreate?.start || null,
                            end: date,
                        },
                        changeType: null,
                    })
                }
            }
        } else {
            if (date) {
                setIsLoading(true)
                const dateRangeToInsert = {
                    start: dateRanges[dateRanges.length - 1].start,
                    end: date,
                }
                await insertPeriod(dateRangeToInsert)
            }
            setIsSelecting(false)
        }
        setIsLoading(false)
    }

    return (
        <div className="calendar-container">
            <MenuModal
                setIsBackgroundLoading={setIsBackgroundLoading}
                visible={menuModalVisible}
                setVisible={setMenuModalVisible}
                title={menuModalText}
                startPeriod={startPeriod}
                dateClicked={currentDate}
                setCurrentDate={setCurrentDate}
                dateRanges={dateRanges}
                deletePeriod={deletePeriod}
                docDates={docDates}
                setDocDates={setDocDates}
                userNotes={userNotes}
                setUserNotes={setUserNotes}
                updateData={updateData}
                rosterDates={rosterDates}
                userLocationDates={userLocationDates}
                setUserLocationDates={setUserLocationDates}
                session={session}
            />
            <RawCalendar
                onDayClick={handleDayClick}
                layoutDates={layoutDates}
                month={calendarMonth}
                onMonthChange={setCalendarMonth}
            />
            {(isSelecting || selectingEXPeriod.changeType || createEXPeriod.changeType) && (
                <Button
                    type="primary"
                    className="cancel-select-btn"
                    onClick={cancelSelect}
                    icon={<CloseCircleOutlined />}
                >
                    Cancel
                </Button>
            )}
            <PeriodConfig
                dateRanges={llPeriods}
                setSelectingEXPeriod={setSelectingEXPeriod}
                selectingEXPeriod={selectingEXPeriod}
                createEXPeriod={createEXPeriod}
                setCreateEXPeriod={setCreateEXPeriod}
                updateEXPeriods={updateData}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default SimpleCalendarPage
