import React from 'react'
import AppPage from '../components/AppNavBar/index'
import Pagewrapper from '../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <AppPage />
        </Pagewrapper>
    )
}
