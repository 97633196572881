import { Progress, Modal, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.less'
import { SyncOutlined } from '@ant-design/icons'

declare const ProgressStatuses: ['normal', 'exception', 'active', 'success']

export default function DownloadProgress({
    exportFunction,
    requestDownload,
    setIsLoading,
    onFail,
}: {
    exportFunction: (callback: any) => Promise<void>
    requestDownload: boolean
    setIsLoading: (isDone: boolean) => void
    onFail: any
}) {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [collectProgress, setCollectProgress] = useState(0)
    const [collectStatus, setCollectStatus] = useState<typeof ProgressStatuses[number]>('active')
    const [buildProgress, setBuildProgress] = useState(0)
    const [buildStatus, setBuildStatus] = useState<typeof ProgressStatuses[number]>('normal')
    const [isDone, setIsDone] = useState(false)

    function exportCallback(meta) {
        if (meta.type === 'collect') {
            setCollectProgress(meta.meta.percent)
            if (meta.meta.percent === 100) {
                setCollectStatus('success')
                setBuildStatus('active')
            }
        } else if (meta.type === 'build') {
            setBuildProgress(meta.meta.percent)
        }
    }

    function close() {
        setVisible(false)
        setLoading(false)
        setCollectProgress(0)
        setCollectStatus('active')
        setBuildProgress(0)
        setBuildStatus('normal')
        setIsDone(false)
    }

    useEffect(() => {
        if (!loading && requestDownload) {
            setVisible(true)
            setLoading(true)
            exportFunction(exportCallback)
                .then(() => {
                    setCollectStatus('success')
                    setBuildStatus('success')
                    setIsLoading(false)
                })
                .catch((err) => {
                    if (collectStatus === 'active') {
                        setCollectStatus('exception')
                    } else if (collectStatus === 'success' && buildStatus === 'active') {
                        setBuildStatus('exception')
                    }
                    setVisible(false)
                    setCollectProgress(0)
                    setCollectStatus('active')
                    setBuildProgress(0)
                    setBuildStatus('normal')
                    setIsDone(false)
                    onFail(err)
                })
                .finally(() => {
                    setIsDone(true)
                })
        }
    }, [
        requestDownload,
        exportFunction,
        setIsLoading,
        setLoading,
        buildStatus,
        collectStatus,
        loading,
        onFail,
    ])

    return (
        <Modal
            visible={visible}
            footer={
                <Button disabled={!isDone} icon={!isDone && <SyncOutlined spin />} onClick={close}>
                    Return
                </Button>
            }
        >
            <div>
                <h4>Collect Data</h4>
                <Progress
                    percent={collectProgress}
                    status={collectStatus}
                    format={(p) => `${Math.round(p)} %`}
                />
                <h4>Compile Excel File</h4>
                <Progress
                    percent={buildProgress}
                    status={buildStatus}
                    format={(p) => `${Math.round(p)} %`}
                />
                {isDone && <h3>Your compliance report should now download!</h3>}
            </div>
        </Modal>
    )
}
