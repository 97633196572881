import React, { useEffect, useState } from 'react'
import './style.less'
import {
    CalendarOutlined,
    FundOutlined,
    SettingOutlined,
    CalendarFilled,
    SettingFilled,
    FundFilled,
    SyncOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Spin } from 'antd'
import SimpleCalendarPage from '../../components/SimpleCalendar'
import OverviewPage from '../Overview/index'
import SettingsPage from '../Settings/index'
import {
    mapEXPeriodData,
    mapGraphPeriodData,
    userNotesReceiver,
    mapUserNotes,
    IRosterDate,
    alterGraphDate,
    UserLocationDate,
} from '../../lib/dateFunctions'
import { docDate, IDateRange, IGraphData, IGraphPoint, INextDayRelease } from '../../lib/interfaces'
import { useAuth } from '../../hooks/useAuth'
import { mapPeriodData } from '../../lib/dateFunctions'
import {
    IChangePeriod,
    IPeriodCache,
} from '../../lib/interfaces'
import { useCacheFetcher } from '../../services/http'
import { startOfToday, toDate } from 'date-fns'
import LogRocket from 'logrocket'
import { AppHeader } from '../AppHeader'
import { hasCookieConsentStatistic } from '../../components/CookieModal'
const { Footer } = Layout

export enum selectTab {
    Calendar = 'Calendar',
    Overview = 'Overview',
    Settings = 'Settings',
}

export function selectTabFun(value: string) {
    switch (value) {
        case 'Calendar':
            return selectTab.Calendar
        case 'Overview':
            return selectTab.Overview
        case 'Settings':
            return selectTab.Settings
        default:
            return selectTab.Calendar
    }
}

const mapDocDates = (docDates: docDate[]) => {
    return docDates.map((e) => {
        return {
            documentation_date: new Date(e.documentation_date),
            date_is_included_in_report: e.date_is_included_in_report,
        }
    })
}

function NavBarLayout() {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isBackgroundLoading, setIsBackgroundLoading] = useState<boolean>(false)

    const [calendarMonth, setCalendarMonth] = useState<Date>(new Date())

    const [dateRanges, setDateRanges] = useState<IDateRange[]>([])
    const updateDKPeriodData = (data) => {
        setDateRanges(mapPeriodData(data))
    }
    const dateRangesFetcher = useCacheFetcher(updateDKPeriodData)
    const fecthDateRanges = async (cacheReturn = true) => {
        await dateRangesFetcher('/periods', cacheReturn)
    }

    const [currentPeriod, setCurrentPeriod] = useState<IPeriodCache>()
    const [llPeriods, setLlPeriods] = useState<IPeriodCache[]>([])
    const updatellPeriodsData = (data) => {
        const newPeriods = mapEXPeriodData(data?.dateRanges)
        setLlPeriods(newPeriods)
        setCurrentPeriod(
            newPeriods.find(
                (p) =>
                    (p.start === null || startOfToday() >= toDate(p.start)) &&
                    (p.end === null || startOfToday() <= toDate(p.end))
            )
        )
    }
    const llPeriodsFetcher = useCacheFetcher(updatellPeriodsData)
    const fecthLlPeriods = async (cacheReturn = true) => {
        await llPeriodsFetcher('/get-ll-periods', cacheReturn)
    }

    const [allGraphData, setAllGraphData] = useState<IGraphData>({ dataPoints: [] })
    const updateallGraphData = (data) => {
        if (!data) return
        setAllGraphData(mapGraphPeriodData(data))
        setIsLoading(false)
    }
    const allGraphDataFetcher = useCacheFetcher(updateallGraphData)
    const fecthAllGraphData = async (cacheReturn = true) => {
        await allGraphDataFetcher('/get-graph-data', cacheReturn)
    }

    const [nextDayRelease, setNextDayRelease] = useState<INextDayRelease>({
        eligible: false,
        days: 0,
        release_date: new Date(),
    })
    const updateNextDayRelease = (data) => {
        if (!data) return
        setNextDayRelease(data)
        setIsLoading(false)
    }
    const nextDayReleaseFetcher = useCacheFetcher(updateNextDayRelease)
    const fecthNextDayRelease = async (cacheReturn = true) => {
        await nextDayReleaseFetcher('/get-next-day-release', cacheReturn)
    }

    const [docDates, setDocDates] = useState<docDate[]>([])
    const updateDocDates = (data) => {
        if (!data) return
        setDocDates(mapDocDates(data.doc_dates))
    }
    const docDatesFetcher = useCacheFetcher(updateDocDates)
    const fecthDocDates = async (cacheReturn = true) => {
        await docDatesFetcher('/doc-days', cacheReturn)
    }

    const [userNotes, setUserNotes] = useState<userNotesReceiver[]>([])
    const updateUserNotes = (data) => {
        if (!data) return
        setUserNotes(mapUserNotes(data))
    }
    const userNotesFetcher = useCacheFetcher(updateUserNotes)
    const fecthUserNotes = async (cacheReturn = true) => {
        await userNotesFetcher('/user-notes/', cacheReturn)
    }

    const [rosterDates, setRosterDates] = useState<IRosterDate[]>([])
    const updateRosterDates = (data) => {
        if (!(data && data.roster_dates)) return
        setRosterDates(data.roster_dates || [])
    }
    const rosterDatesFetcher = useCacheFetcher(updateRosterDates)
    const fecthRosterDates = async (cacheReturn = true) => {
        await rosterDatesFetcher('/roster/dates', cacheReturn)
    }

    const [userLocationDates, setUserLocationDates] = useState<UserLocationDate[]>([])
    const updateUserLocationDates = (data) => {
        if (!data?.dates) return
        setUserLocationDates(data.dates)
    }
    const userLocationDatesFetcher = useCacheFetcher(updateUserLocationDates)
    const fecthUserLocationDates = async (cacheReturn = true) => {
        await userLocationDatesFetcher('/user-locations/', cacheReturn)
    }

    useEffect(() => {
        fecthDateRanges()
        fecthLlPeriods()
        fecthAllGraphData()
        fecthNextDayRelease()
        fecthDocDates()
        fecthUserNotes()
        fecthRosterDates()
        fecthUserLocationDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [isSelecting, setIsSelecting] = useState<boolean>(false)
    const [selectingEXPeriod, setSelectingEXPeriod] = useState<IChangePeriod>({
        period: null,
        changeType: null,
    })
    const [selectedTab, setSelectedTab] = useState<selectTab>(selectTab.Calendar)
    const [userName, setUserName] = useState<string>('')

    const menuClick = (value: any) => {
        if (isSelecting) {
            setDateRanges(dateRanges.slice(0, dateRanges.length - 1))
            setIsSelecting(false)
        }
        setSelectedTab(selectTabFun(value.key))
    }
    const { session } = useAuth('')

    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && hasCookieConsentStatistic()) {
            LogRocket.identify(session?.user?.user_id, {
                name: session?.user?.name,
                email: session?.user?.email,

                // Custom variables
                feature_flags: session?.user?.feature_flags,
                has_paid: session?.user?.has_paid,
                is_mail_verified: session?.user?.is_mail_verified,
                current_period_end: session?.user?.current_period_end.toISOString(),
                app_version: process.env.REACT_APP_VERSION,
                front_end_version: global['appVersion'],
            })
        }
    }, [session])

    const updateData = async (
        partial: string = 'all',
        targetDate = null,
        alterFun: (dp: IGraphPoint) => IGraphPoint = null
    ) => {
        switch (partial) {
            case 'graph':
                setAllGraphData(alterGraphDate(allGraphData, targetDate, alterFun))
                break
            case 'all':
                updateDataBackground()
                break
            default:
                const requests = partial.split(' ')
                updateDataBackground(requests)
                break
        }
    }

    const updateDataBackground = async (
        includeRequests: string[] = ['graph', 'll-period', 'day-release', 'roster']
    ) => {
        setIsBackgroundLoading(true)
        if (includeRequests.includes('graph')) {
            await fecthAllGraphData(false)
        }
        if (includeRequests.includes('ll-period')) {
            await fecthLlPeriods(false)
        }
        if (includeRequests.includes('day-release')) {
            await fecthNextDayRelease()
        }
        if (
            includeRequests.includes('roster') &&
            session?.user?.feature_flags?.includes('roster')
        ) {
            await fecthRosterDates(false)
        }
        if (includeRequests.includes('useerLocationDates')) {
            await fecthUserLocationDates(false)
        }
        setIsBackgroundLoading(false)
    }

    useEffect(() => {
        setCurrentPeriod(
            llPeriods.find(
                (p) =>
                    (p.start === null || startOfToday() >= toDate(p.start)) &&
                    (p.end === null || startOfToday() <= toDate(p.end))
            )
        )
    }, [llPeriods])

    useEffect(() => {
        if (!(session && session.user && session.user.name)) return
        setUserName(session.user.name.split(' ')[0] + ' - ')
    }, [session])

    const LoadingComp = <SyncOutlined style={{ fontSize: 24 }} spin />

    if (!session || !session.user)
        return (
            <Spin
                spinning={true}
                indicator={LoadingComp}
                tip="Loading..."
                style={{
                    width: '100%',
                    margin: 'auto',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    marginTop: '30vh',
                    height: '100vh',
                }}
            />
        )

    return (
        <>
            <AppHeader
                isBackgroundLoading={isBackgroundLoading}
                headerText={userName + selectedTab}
                userLocationDates={userLocationDates}
                setUserLocationDates={setUserLocationDates}
            />
            <Spin spinning={isLoading} indicator={LoadingComp} tip="Loading...">
                {selectedTab === selectTab.Calendar && (
                    <SimpleCalendarPage
                        dateRanges={dateRanges}
                        setDateRanges={setDateRanges}
                        isSelecting={isSelecting}
                        setIsSelecting={setIsSelecting}
                        setIsLoading={setIsLoading}
                        setDocDates={setDocDates}
                        docDates={docDates}
                        llPeriods={llPeriods}
                        setSelectingEXPeriod={setSelectingEXPeriod}
                        selectingEXPeriod={selectingEXPeriod}
                        updateData={updateData}
                        allGraphData={allGraphData}
                        session={session}
                        userNotes={userNotes}
                        setUserNotes={setUserNotes}
                        userLocationDates={userLocationDates}
                        setUserLocationDates={setUserLocationDates}
                        rosterDates={rosterDates}
                        calendarMonth={calendarMonth}
                        setCalendarMonth={setCalendarMonth}
                        setIsBackgroundLoading={setIsBackgroundLoading}
                    />
                )}
                {selectedTab === selectTab.Overview && (
                    <OverviewPage
                        dateRanges={dateRanges}
                        setDateRanges={setDateRanges}
                        isSelecting={isSelecting}
                        setIsSelecting={setIsSelecting}
                        allGraphData={allGraphData}
                        docDates={docDates}
                        setDocDates={setDocDates}
                        llPeriods={llPeriods}
                        setSelectingEXPeriod={setSelectingEXPeriod}
                        selectingEXPeriod={selectingEXPeriod}
                        updateData={updateData}
                        session={session}
                        nextDayRelease={nextDayRelease}
                        currentPeriod={currentPeriod}
                        calendarMonth={calendarMonth}
                        setCalendarMonth={setCalendarMonth}
                    />
                )}
                {selectedTab === selectTab.Settings && (
                    <SettingsPage
                        dateRanges={dateRanges}
                        setDateRanges={setDateRanges}
                        isSelecting={isSelecting}
                        setIsSelecting={setIsSelecting}
                        docDates={docDates}
                        setDocDates={setDocDates}
                        llPeriods={llPeriods}
                        allGraphData={allGraphData}
                        setSelectingEXPeriod={setSelectingEXPeriod}
                        selectingEXPeriod={selectingEXPeriod}
                        updateData={updateData}
                        session={session}
                    />
                )}
            </Spin>
            <Footer
                className="nav-footer"
                style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    padding: 0,
                }}
            >
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={['Calendar']}
                    className="NavMenu"
                    onClick={menuClick}
                >
                    <Menu.Item key={selectTab.Calendar} className="NavTab">
                        {(selectedTab === selectTab.Calendar && (
                            <CalendarFilled style={{ fontSize: '24px' }} />
                        )) || <CalendarOutlined style={{ fontSize: '24px' }} />}
                    </Menu.Item>
                    <Menu.Item key={selectTab.Overview} className="NavTab">
                        {(selectedTab === selectTab.Overview && (
                            <FundFilled style={{ fontSize: '24px' }} />
                        )) || <FundOutlined style={{ fontSize: '24px' }} />}
                    </Menu.Item>
                    <Menu.Item key={selectTab.Settings} className="NavTab">
                        {(selectedTab === selectTab.Settings && (
                            <SettingFilled style={{ fontSize: '24px' }} />
                        )) || <SettingOutlined style={{ fontSize: '24px' }} />}
                    </Menu.Item>
                </Menu>
            </Footer>
        </>
    )
}

export default NavBarLayout
