import React, { useState } from 'react'
import './style.less'
import {
    EyeInvisibleOutlined,
    MailOutlined,
    DollarOutlined,
    ReadOutlined,
    LogoutOutlined,
    ExclamationCircleTwoTone,
    CloseCircleTwoTone,
    ScheduleOutlined,
    FileDoneOutlined,
} from '@ant-design/icons'
import {
    Button,
    Checkbox,
    Modal,
} from 'antd'
import { httpGet, httpPost, httpPut } from '../../services/http'
import { Link, navigate } from "gatsby"
import { createExcelFile } from './exportData'
import { ChangePasswordModal } from './PasswordModal'
import { ChangeEmailModal } from './EmailModal'
import { loadStripe } from '@stripe/stripe-js'
import { UserInfo } from './UserInfo'
import RosterMenu from './RosterMenu'
import DownloadProgress from './DownloadProgress'
import { endOfToday, isAfter, toDate } from 'date-fns'
import { Session } from '../../types/session'
import { CustomerPortal, dataModifiers } from '../../lib/interfaces'
import { removeStoredAuthToken } from '../../utils/auth-token'

const api_key = process.env.GATSBY_STRIPE_PUBLIC_KEY || ''

function Settings({ session, updateData }: dataModifiers) {
    const [MBLoading, setMBLoading] = useState(false)
    const [RUVisible, setRUVisible] = useState(false)
    const [DDLoading, setDDLoading] = useState(false)
    const [CRLoading, setCRLoading] = useState(false)
    const [DDKey, setDDKey] = useState(new Date().valueOf())
    const [changePassword, setChangePassword] = useState(false)
    const [changeEmail, setChangeEmail] = useState(false)
    const [configRepModalVisible, setConfigRepModalVisible] = useState(false)
    const [includeDoc, setIncludeDoc] = useState(true)
    const [includeNotes, setIncludeNotes] = useState(true)
    const [includeRoster, setIncludeRoster] = useState(true)

    const signout = async () => {
        await httpPost('/auth/signout')
        removeStoredAuthToken()
        navigate('/', {replace: true})
    }

    const activateSubscriptionModal = () => {
        Modal.confirm({
            icon: <CloseCircleTwoTone twoToneColor={'red'} />,
            title: isAfter(session.user.current_period_end, endOfToday())
                ? 'This feature is not included in the Free Trial'
                : 'This feature requires an active subscription',
            content: 'Please go to the billing portal and activate your account.',
            okText: 'Billing Portal',
            onOk: () => {
                manageBilling()
            },
            cancelText: 'Cancel',
        })
    }

    const goToReporting = async () => {
        setCRLoading(true)
        const res = await httpGet<Session>('/auth/user')
        if (res?.data?.user) {
            if (res?.data?.user.has_paid) {
                navigate('/compliance-reporting')
            } else {
                activateSubscriptionModal()
            }
        }
        setCRLoading(false)
    }

    const uploadRoster = () => {
        setRUVisible(true)
    }

    const manageBilling = async () => {
        setMBLoading(true)
        try {
            const res = await httpPost<CustomerPortal>('/auth/customer-portal')
            if (!res.data) return
            if (res.data.url && typeof window !== 'undefined') {
                window.location.href = res.data.url
            } else if (res.data.sessionId) {
                const sessId = res.data.sessionId
                const stripe = await loadStripe(api_key)
                const stripeRes = await stripe?.redirectToCheckout({
                    sessionId: sessId,
                })
                Modal.error({
                    title: 'Could not complete checkout',
                    content: stripeRes?.error.message,
                })
            }
        } catch (error) {
            if (error.status === 401) {
                Modal.error({
                    title: 'You need to verify your E-mail',
                    content:
                        'You need a verified E-mail before you can start your subscription.',
                })
            }
        } finally {
            setMBLoading(false)
        }
    }

    async function exportFunction(callback) {
        return await createExcelFile(
            session.user,
            includeDoc,
            includeNotes,
            !includeRoster,
            callback
        )
    }

    const onExportFail = (error) => {
        if (error.status === 401) {
            activateSubscriptionModal()
        }
        setDDLoading(false)
        setDDKey(new Date().valueOf())
    }

    const exportToCSV = () => {
        setDDLoading(true)
    }

    function renderBillingButton() {
        if (session.user.has_paid) {
            return (
                <Button
                    loading={MBLoading}
                    type="primary"
                    onClick={manageBilling}
                    icon={<DollarOutlined />}
                >
                    Manage Billing
                </Button>
            )
        }
        if (isAfter(toDate(session.user?.current_period_end), toDate(new Date()))) {
            return (
                <Button
                    onClick={manageBilling}
                    loading={MBLoading}
                    type="primary"
                    icon={<DollarOutlined />}
                >
                    Subscribe
                </Button>
            )
        }
        return (
            <Button
                loading={MBLoading}
                type="primary"
                onClick={manageBilling}
                icon={<DollarOutlined />}
            >
                Subscribe
            </Button>
        )
    }

    function renderReportsPlatform() {
        return (
            <Button
                loading={CRLoading}
                onClick={goToReporting}
                className="settings-button"
                type="primary"
                icon={<FileDoneOutlined />}
            >
                Compliance Reports
            </Button>
        )
    }

    return (
        <div className="settings-container">
            <UserInfo session={session} />
            {renderReportsPlatform()}
            {session.user.feature_flags &&
                session.user.feature_flags?.split(' ').includes('roster') && (
                <Button
                    className="settings-button"
                    type="primary"
                    onClick={uploadRoster}
                    icon={<ScheduleOutlined />}
                >
                        Roster Blocks
                </Button>
            )}
            {renderBillingButton()}
            <Button
                type="primary"
                onClick={() => {
                    setChangeEmail(true)
                }}
                icon={<MailOutlined />}
            >
                Change Email
            </Button>
            <Button
                type="primary"
                onClick={() => {
                    setChangePassword(true)
                }}
                icon={<EyeInvisibleOutlined />}
            >
                Change Password
            </Button>
            <Button className="settings-button" type="primary">
                <Link to="/onboarding/">
                    <ReadOutlined /> User Guide
                </Link>
            </Button>

            <Button className="settings-button" type="primary">
                <Link to="/terms/">
                    <ReadOutlined /> Terms
                </Link>
            </Button>
            <Button
                className="settings-button"
                type="primary"
                onClick={signout}
                icon={<LogoutOutlined />}
            >
                Sign Out
            </Button>
            {/* <p>version: {global['appVersion']}</p> */}
            {/* <p>version: {process.env.REACT_APP_VERSION}</p> */}
            <ChangePasswordModal
                visible={changePassword}
                setVisible={setChangePassword}
                submitRequest={(values) => httpPut('/auth/change-password', values)}
                includeExisting={true}
            />
            <ChangeEmailModal
                visible={changeEmail}
                setVisible={setChangeEmail}
                session={session}
                submitRequest={(values) => httpPut('/auth/change-email', values)}
            />
            <RosterMenu visible={RUVisible} setVisible={setRUVisible} updateData={updateData} />
            <Modal
                visible={configRepModalVisible}
                onCancel={() => {
                    setConfigRepModalVisible(false)
                }}
                onOk={() => {
                    setConfigRepModalVisible(false)
                    exportToCSV()
                }}
                title={
                    <>
                        <ExclamationCircleTwoTone
                            twoToneColor="#EEB041"
                            style={{ fontSize: 24, marginRight: 10 }}
                        />
                        Confirm Download
                    </>
                }
            >
                You are about to extract all data for your exemption periods, and accepts according
                to agreed terms, that ll33a.dk disclaims all liability for all content. Be advised,
                exemption periods with an open start will not be downloaded.
                <br />
                <Checkbox checked={includeDoc} onChange={() => setIncludeDoc(!includeDoc)}>
                    Include Documentation
                </Checkbox>
                <br />
                <Checkbox checked={includeNotes} onChange={() => setIncludeNotes(!includeNotes)}>
                    Include Notes
                </Checkbox>
                {session.user.feature_flags &&
                    session.user.feature_flags?.split(' ').includes('roster') && (
                    <>
                        <br />
                        <Checkbox
                            checked={includeRoster}
                            onChange={() => setIncludeRoster(!includeRoster)}
                        >
                                Include Roster
                        </Checkbox>
                    </>
                )}
            </Modal>
            <div key={DDKey}>
                <DownloadProgress
                    exportFunction={exportFunction}
                    requestDownload={DDLoading}
                    setIsLoading={setDDLoading}
                    onFail={onExportFail}
                />
            </div>
        </div>
    )
}

export default Settings
