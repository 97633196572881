import { format, toDate } from 'date-fns'
import { da } from 'date-fns/esm/locale'
import * as FileSaver from 'file-saver'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import Excel, {
    Column,
    // Cell
} from 'exceljs'
import { User } from '../../types/session'
import { httpGet } from '../../services/http'
// import Excel from 'exceljs/dist/es5/exceljs.browser'
// const JSZipUtils = require('jszip-utils');
// const Excel = require('exceljs/dist/es5/exceljs.browser');

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'
const reportDateFormat = 'yyyy-MM-dd'

interface IAppendixref {
    url: string
    suffix?: string
}

interface periodDate {
    date: number | Date
    wasindk: boolean
    daycount: number
    appendixref: IAppendixref[]
    note_content: string
    // star air roster
    roster_categories: string
    roster_id: string
    // star air roster
}

// star air roster
interface rosterPeriod {
    id: string
    start: number | Date
    end: number | Date
    pdf_file: IAppendixref
}
// star air roster

interface ExcelPeriod {
    start: number | Date
    end: number | Date
    dates: periodDate[]
    earliest_end: number | Date
    has_roster: boolean
    // star air roster
    roster_periods: rosterPeriod[]
    // star air roster
}

export interface ExcelInput {
    periods: ExcelPeriod[]
}

function urlToPromise(url: string) {
    return JSZipUtils.getBinaryContent(url, null)
}

export const createExcelFile = async (
    user: User | null,
    includeDoc: boolean = true,
    includeNotes: boolean = true,
    disableRosterOverride: boolean = false,
    callBack: (meta: any) => void
) => {
    function jsZipCallback(meta) {
        callBack({
            meta: meta,
            type: 'build',
        })
    }

    const res = await httpGet<ExcelInput>('/all-data')
    callBack({
        meta: { percent: 100 },
        type: 'collect',
    })
    const data: ExcelInput = res.data
    var zipFile = new JSZip()
    const periods = data.periods
    for (var idx = 0; idx < periods.length; idx++) {
        const period = periods[idx]
        // star air roster
        const hasRoster: boolean =
            period.has_roster && period.roster_periods.length > 0 && !disableRosterOverride
        // star air roster
        const periodName = `period-${format(toDate(new Date(period.start)), reportDateFormat)}--${
            period.end ? format(toDate(new Date(period.end)), reportDateFormat) : 'ukendt'
        }`
        var periodFolder = zipFile.folder(periodName)
        let images
        var roster
        var rosterMap = {}
        if (includeDoc) {
            images = periodFolder.folder('appendix')
        }
        // star air roster
        if (hasRoster) {
            roster = periodFolder.folder('arbejds-kalendere')
            for (var i = 0; i < period.roster_periods.length; i++) {
                const imgFileName = `roster-period-${i + 1}.pdf`
                const imgStream = urlToPromise(period.roster_periods[i].pdf_file.url)
                roster.file(imgFileName, imgStream, { binary: true })
                rosterMap[period.roster_periods[i].id] = imgFileName
            }
        }
        // star air roster
        const workbook = new Excel.Workbook()
        workbook.creator = user?.name
        workbook.lastModifiedBy = user?.name
        workbook.created = new Date()
        workbook.modified = new Date()
        workbook.lastPrinted = new Date()
        workbook.views = [
            {
                x: 0,
                y: 0,
                width: 10000,
                height: 20000,
                firstSheet: 0,
                activeTab: 1,
                visibility: 'visible',
            },
        ]
        const sheet = workbook.addWorksheet(
            `period-${format(toDate(new Date(period.start)), reportDateFormat)}-${
                period.end ? format(toDate(new Date(period.end)), reportDateFormat) : 'ukendt'
            }`,
            {
                views: [{ state: 'frozen', xSplit: 0, ySplit: 7 }],
            }
        )
        const convCol = {
            hidden: false,
            outlineLevel: 0,
            style: {},
            values: [0],
            letter: 'A',
            number: 1,
            worksheet: sheet,
            isCustomWidth: true,
            isDefault: true,
            headerCount: 1,
            headers: [],
            equivalentTo: (other: Column): boolean => false,
            collapsed: false,
            eachCell: null,
            // eachCell: dummy2,
            defn: '',
        }
        let appendixTitle = 'Bilags referencer'
        let notesTitle = 'Bemærkninger'

        if (includeDoc) {
            appendixTitle = appendixTitle + ' til dokumentation vedlagt i mappen \'appendix\''
        }
        // star air roster
        if (hasRoster) {
            if (includeDoc) {
                appendixTitle =
                    appendixTitle.replace('mappen', 'mapperne') + ' & \'arbejds-kalendere\''
            } else {
                appendixTitle =
                    appendixTitle + ' til arbejdskalender vedlagt i mappen \'arbejds-kalendere\''
            }
        }
        if (!includeDoc) appendixTitle = ''
        if (!includeNotes) notesTitle = ''
        // star air roster
        let cols: Column[] = [
            { ...convCol, header: 'Dato', key: 'date', width: 15 },
            {
                ...convCol,
                header: 'Befandt sig i DK den pågældende dag\n(\'x\' for ja)',
                key: 'dkDay',
                width: 15,
            },
            {
                ...convCol,
                header: 'Sammentælling af dage i DK på 6 måneder bagud',
                key: 'dayCount',
                width: 15,
            },
            {
                ...convCol,
                header: '42 DAGES REGLEN ER OVERSKREDET\n(\'x\' for ja)',
                key: 'exceeding',
                width: 15,
            },
            { ...convCol, header: appendixTitle, key: 'apx', width: 20 },
            { ...convCol, header: notesTitle, key: 'notes', width: 20 },
        ]
        // star air roster
        if (hasRoster)
            cols.push({
                ...convCol,
                header: 'Arbejdsdag\n(\'x\' for ja)',
                key: 'dutyNotes',
                width: 13,
            })
        // star air roster
        sheet.columns = cols
        for (var jdx = 0; jdx < period.dates.length; jdx++) {
            const periodDate = period.dates[jdx]
            var apxRef: string[] = []
            // star air roster
            if (periodDate.roster_id && hasRoster) {
                if (periodDate.roster_id in rosterMap) {
                    apxRef.push(rosterMap[periodDate.roster_id])
                }
            }
            // star air roster
            if (includeDoc && periodDate.appendixref) {
                for (var iidx = 0; iidx < periodDate.appendixref.length; iidx++) {
                    const url = periodDate.appendixref[iidx].url
                    if (!images) return
                    let imgFileName =
                        format(toDate(new Date(periodDate.date)), reportDateFormat) + `-${iidx + 1}`
                    if (periodDate.appendixref[iidx].suffix) {
                        imgFileName = imgFileName + '.' + periodDate.appendixref[iidx].suffix
                    }
                    apxRef.push(imgFileName)
                    const imgStream = urlToPromise(url)
                    images.file(imgFileName, imgStream, { binary: true })
                }
            }
            const apxStr = apxRef.join(',\n')
            let newRow = {
                date: format(toDate(new Date(periodDate.date)), reportDateFormat, { locale: da }),
                dkDay: periodDate.wasindk ? 'x' : null,
                dayCount: periodDate.daycount,
                exceeding: periodDate.daycount > 42 ? 'x' : null,
            }
            if (includeDoc || hasRoster) newRow['apx'] = apxStr
            if (includeNotes)
                newRow['notes'] = periodDate.note_content
                    ? periodDate.note_content
                    : jdx === 0
                        ? 'Udrejsedag'
                        : jdx === period.dates.length - 1
                            ? period.end
                                ? 'Hjemrejsedag'
                                : null
                            : null
            // star air roster
            if (hasRoster) newRow['dutyNotes'] = periodDate.roster_categories ? 'x' : null
            // star air roster
            const row = sheet.addRow(newRow)
            if (periodDate.daycount > 42) {
                row.getCell(4).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFF0000' },
                }
            }
            // no color for dk days
            // if (periodDate.wasindk) {
            //     row.getCell(2).fill = {
            //         type: 'pattern',
            //         pattern: 'solid',
            //         fgColor: { argb: '90EE90' }
            //     };
            // }
            row.getCell(1).alignment = { horizontal: 'center' }
            row.getCell(2).alignment = { horizontal: 'center' }
            row.getCell(3).alignment = { horizontal: 'center' }
            row.getCell(4).alignment = { horizontal: 'center' }
            row.getCell(5).alignment = { wrapText: true }
            row.getCell(6).alignment = { wrapText: true }
            row.getCell(7).alignment = { horizontal: 'center' }
        }
        sheet.insertRow(1, { date: 'Navn:', dkDay: user?.name, exceeding: 'Cpr.nr.:' })
        sheet.insertRow(2, {
            date: 'BEREGNING AF UDLANDSOPHOLD I HENHOLD TIL LIGNINGSLOVEN § 33 A.',
        })
        sheet.insertRow(3, {
            date: 'Startdato for udlandsophold:',
            notes: format(toDate(new Date(period.start)), reportDateFormat),
        })
        sheet.insertRow(4, {
            date: 'Slutdato for udlandsophold (hvis kendt):',
            notes: period.end ? format(toDate(new Date(period.end)), reportDateFormat) : 'ukendt',
        })
        sheet.insertRow(5, {
            date: 'Dato for mindst 6 måneder fra beregningsdato:',
            notes: format(toDate(new Date(period.earliest_end)), reportDateFormat),
        })
        sheet.insertRow(6, {
            date: 'Start- og slut-dato for udenlandsopholdet medregnes ikke i sammentællingen jf.:',
        })
        sheet.mergeCells('B1:C1')
        // star air roster
        if (hasRoster) {
            // CPR
            sheet.mergeCells('E1:G1')
            // long title
            sheet.mergeCells('A2:G2')
            // startdate title
            sheet.mergeCells('F3:G3')
            // enddate title
            sheet.mergeCells('F4:G4')
            // first possible end title
            sheet.mergeCells('F5:G5')
            // star air roster
        } else {
            // CPR
            sheet.mergeCells('E1:F1')
            // long title
            sheet.mergeCells('A2:F2')
        }
        sheet.mergeCells('A3:E3')
        sheet.mergeCells('A4:E4')
        sheet.mergeCells('A5:E5')
        sheet.mergeCells('A6:E6')
        sheet.getCell('A1').alignment = { wrapText: true, horizontal: 'right' }
        sheet.getCell('D1').alignment = { wrapText: true, horizontal: 'right' }
        sheet.getCell('A2').alignment = { wrapText: true, horizontal: 'center' }
        sheet.getCell('A7').alignment = { wrapText: true }
        sheet.getCell('B7').alignment = { wrapText: true }
        sheet.getCell('C7').alignment = { wrapText: true }
        sheet.getCell('D7').alignment = { wrapText: true }
        sheet.getCell('E7').alignment = { wrapText: true }
        sheet.getCell('F7').alignment = { wrapText: true }
        // star air roster
        if (hasRoster) sheet.getCell('G7').alignment = { wrapText: true }
        // star air roster
        const metaFont = {
            bold: true,
        }
        sheet.getCell('A1').font = metaFont
        sheet.getCell('B1').font = metaFont
        sheet.getCell('D1').font = metaFont
        sheet.getCell('E1').font = metaFont
        sheet.getCell('A7').font = metaFont
        sheet.getCell('B7').font = metaFont
        sheet.getCell('C7').font = metaFont
        sheet.getCell('D7').font = metaFont
        sheet.getCell('E7').font = metaFont
        sheet.getCell('F7').font = metaFont
        if (hasRoster) sheet.getCell('G7').font = metaFont
        sheet.getCell('A2').font = {
            bold: true,
            size: 20,
        }
        sheet.getCell('B1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
        }
        sheet.getCell('E1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
        }
        sheet.getCell('F3').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
        }
        sheet.getCell('F4').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
        }
        sheet.getCell('F5').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
        }
        sheet.getCell('F6').value = {
            text: 'SKM2003.405.HR',
            hyperlink: 'https://skat.dk/skat.aspx?oID=169672',
            // tooltip: 'skat.dk'
        }

        const excelBuffer = await workbook.xlsx.writeBuffer()
        const fileData = new Blob([excelBuffer], { type: fileType })
        periodFolder.file('ll33a-dk-days' + fileExtension, fileData)
    }
    const zippedFile = await zipFile.generateAsync({ type: 'blob' }, jsZipCallback)
    FileSaver.saveAs(zippedFile, 'll33a-dk-days.zip')
}
