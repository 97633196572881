import { Button, Divider, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.less'
import { CloudUploadOutlined } from '@ant-design/icons'
import RosterUpload from './RosterUpload'
import { httpGet } from '../../services/http'
import RosterCard, { IRosterPeriod, IRosterPeriodRes } from './RosterCard'
import { IGraphPoint } from '../../lib/interfaces'

interface RosterModal {
    visible: boolean
    setVisible: any
    updateData: (partial?: string, targetDate?, alterFun?: (dp: IGraphPoint) => IGraphPoint) => void
}

export default function RosterMenu({ visible, setVisible, updateData }: RosterModal) {
    const [RUVisible, setRUVisible] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<IRosterPeriod[]>([])

    useEffect(() => {
        if (!visible) {
            setLoading(false)
            return
        }
        httpGet<IRosterPeriodRes>('/roster').then((r) => {
            if (r.data && r.data.roster_periods) {
                setData(r.data.roster_periods)
            }
            setLoading(false)
        })
    }, [visible])

    function closeModal() {
        setRUVisible(false)
        setVisible(false)
    }

    const uploadRoster = () => {
        setRUVisible(true)
    }

    return (
        <Modal visible={visible} onCancel={closeModal} footer={[]} destroyOnClose>
            <h2>Uploaded Roster periods</h2>
            <Divider />
            {data?.map((p) => {
                return (
                    <RosterCard
                        key={p.id}
                        setData={setData}
                        rosterPeriod={p}
                        updateData={updateData}
                    />
                )
            })}
            <Divider />
            <Button
                loading={loading}
                className="settings-button roster-submit-btn"
                type="primary"
                onClick={uploadRoster}
                icon={<CloudUploadOutlined />}
            >
                Upload Roster
            </Button>
            <RosterUpload
                visible={RUVisible}
                setVisible={setRUVisible}
                setData={setData}
                updateData={updateData}
            />
        </Modal>
    )
}
